import React, { useState, useCallback, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import proofIcon from "../assets/icons/Green/APX_Delivered._80x80.png";
import { Trans, useTranslation } from "react-i18next";

import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  FetchPODDetails,
  HttpError,
  NotFoundError,
  UnknownError,
  VerifyPOD,
} from "../functions/ApiCalls";

import { useNavigate, useParams } from "react-router";

const PODECommerceModal = () => {
  const { trackingNumber, postalCode } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [verificationError, setVerificationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [receivedBy, setReceivedBy] = useState("");
  const [orderImages, setOrderImages] = useState([]);
  const postalCodeRef = useRef();

  const downloadImagesAsZip = () => {
    const zip = new JSZip();

    orderImages.forEach((imageData, index) => {
      // Convert base64 to a blob
      const byteCharacters = atob(imageData);
      const byteArrays = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i);
      }

      // Add the blob to the zip
      zip.file(`image_${index + 1}.png`, byteArrays, { binary: true });
    });

    // Generate the zip file
    zip.generateAsync({ type: "blob" }).then((blob) => {
      // Save the zip file using file-saver
      saveAs(blob, "images.zip");
    });
  };

  const doVerification = useCallback(
    (postalCode) => {
      const execute = async () => {
        setIsLoading(true);

        const result = await VerifyPOD(trackingNumber, null, postalCode);

        if (result instanceof HttpError) {
          setVerificationError(t("PODECommerceModal.verifyErrors.httpError"));
          setIsLoading(false);
          return;
        }

        if (result instanceof NotFoundError) {
          setVerificationError(
            t("PODECommerceModal.verifyErrors.notFoundError")
          );
          setIsLoading(false);
          return;
        }

        if (result instanceof UnknownError) {
          setVerificationError(
            t("PODECommerceModal.verifyErrors.unknownError")
          );
          setIsLoading(false);
          return;
        }

        const podDataResult = await FetchPODDetails(
          trackingNumber,
          null,
          postalCode
        );

        if (podDataResult instanceof HttpError) {
          setVerificationError(
            t("PODECommerceModal.fetchErrors.httpError", {
              status: podDataResult.status,
            })
          );
          setIsLoading(false);
          return;
        }

        if (podDataResult instanceof NotFoundError) {
          setVerificationError(
            t("PODECommerceModal.fetchErrors.notFoundError")
          );
          setIsLoading(false);
          return;
        }

        if (podDataResult instanceof UnknownError) {
          setVerificationError(t("PODECommerceModal.fetchErrors.unknownError"));
          setIsLoading(false);
          return;
        }

        setReceivedBy(podDataResult.receivedBy);
        setOrderImages(podDataResult.orderImage);

        setVerificationError("");
        setCurrentPage(currentPage + 1);
      };
      execute();
    },
    [currentPage, t, trackingNumber]
  );

  useEffect(() => {
    const exec = async () => {
      postalCodeRef.current.value = postalCode;
      doVerification(postalCode);
    };
    if (postalCode && postalCodeRef.current) {
      exec();
    }
    // doVerification was intentionally left out of the dependency array to prevent an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  const verifyPage1 = useCallback(
    async (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);
      const formDestinationPostalCode = formData
        .get("destinationPostalCode")
        .trim();

      if (formDestinationPostalCode === "") {
        setVerificationError(
          t("PODECommerceModal.verifyErrors.destinationPostalCodeEmpty")
        );
        return;
      }

      await doVerification(formDestinationPostalCode);
    },
    [doVerification, t]
  );

  const onClose = () => {
    let path = "..";
    if (postalCode) {
      path = "../..";
    }
    navigate(path, { relative: "path" });
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img src={proofIcon} alt="sms" className="me-2" height="40px"></img>
          <Trans i18nKey="PODECommerceModal.title">
            View Proof of Delivery
          </Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentPage === 1 && (
          <form onSubmit={verifyPage1}>
            <fieldset
              disabled={isLoading}
              className="d-flex justify-content-center flex-column"
            >
              <p>
                <Trans i18nKey="PODECommerceModal.prompt">
                  Enter your delivery postal code so we can verify you.
                </Trans>
              </p>
              <div className="mb-3">
                <label htmlFor="destinationPostalCode" className="form-label">
                  <Trans i18nKey="PODECommerceModal.destinationPostalCode">
                    Destination Postal Code
                  </Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="destinationPostalCode"
                  name="destinationPostalCode"
                  aria-describedby="destinationPostalCode"
                  placeholder="L4W5J2"
                  maxLength="7"
                  autoFocus={true}
                  ref={postalCodeRef}
                />
              </div>

              {verificationError && (
                <b>
                  <p className="text-danger text-center">{verificationError}</p>
                </b>
              )}

              <button className="btn btn-primary" type="submit">
                {isLoading ? "..." : t("Generic.continue")}
              </button>
            </fieldset>
          </form>
        )}

        {currentPage === 2 && (
          <div className="d-flex justify-content-center flex-column">
            {orderImages.length > 0 ? (
              <>
                <Carousel
                  controls={orderImages.length > 1}
                  indicators={orderImages.length > 1}
                >
                  {orderImages.map((imageData, index) => (
                    <Carousel.Item key={index} style={{ minHeight: "300px" }}>
                      <img
                        style={{
                          objectFit: "scale-down",
                        }}
                        className="d-block w-100"
                        src={`data:image/png;base64,${imageData}`}
                        alt={`${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <button
                  className="btn btn-link mt-3"
                  onClick={downloadImagesAsZip}
                >
                  <Trans i18nKey="PODECommerceModal.downloadImages">
                    Download Images
                  </Trans>
                </button>
              </>
            ) : (
              <p>
                <Trans i18nKey="PODECommerceModal.noImages">
                  There is no POD image for this order.
                </Trans>
              </p>
            )}
            <br />
            <p className="text-center">
              <Trans i18nKey="PODECommerceModal.receivedBy">
                Received by: <b>{{ receivedBy }}</b>
              </Trans>
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PODECommerceModal;
