import React from "react";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
function OrderDetails({ data }) {
  useTranslation();// Required (from the useTranslation import, via react-i18next) to make the translations work from within this component.
  const { orderDetails: { deliveryCity, deliveryProvince, lastUpdated, lastUpdatedTimeZone, reference } } = data;
  return (
    <Row className="ms-0 me-0">
      <Col xs={12} lg={5}>
        <p>
          <Trans i18nKey="OrderDetails.destination">
            Destination City: <b>{{ deliveryCity }}, {{ deliveryProvince }}</b>
          </Trans>
        </p>
        <p>
          <Trans i18nKey="OrderDetails.lastUpdated">
            Last updated: <b>{{ lastUpdated }} {{ lastUpdatedTimeZone }}</b>
          </Trans>
        </p>
      </Col>
      <Col xs={12} lg={4}>
        <p>
          <Trans i18nKey="OrderDetails.reference">
            Reference Number: <b>{{ reference }}</b>

          </Trans>
        </p>
      </Col>
    </Row>
  );
}

export default OrderDetails;
