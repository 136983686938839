import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { format as formatDate, isDate, parse } from "date-fns";
import { enCA, frCA } from "date-fns/locale";
import enTranslation from "./assets/locales/enCA/translation.json";
import frTranslation from "./assets/locales/frCA/translation.json";

const locales = { en: enCA, fr: frCA };
// fallbackLng: "enCA", // Use this to replace non-found properties with the default language values (so property names don't show up)... disabled for now for testing
//lng: "enCA", // can't use this since it overrides language detection. we want language to be deiected automatically based on the browser's settings
//    defaultNS: 'translation',
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
        enCA: {
            translation: enTranslation,
        },
        frCA: {
            translation: frTranslation,
        },
    },
    fallbackLng: "enCA",
    initImmediate: true,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        try {
          const parsed = parse(value, "yyyy-MM-dd'T'HH:mm:ss", new Date());
          if (isDate(parsed)) {
            const locale = locales[lng];
            return formatDate(parsed, format, { locale });
          }
        } catch (e) {
          return value;
        }
      },
    },
  }, (err, t) => {
      if (err) return console.log('Problem initializing i18n for localization:', err);

      // Load the languages here, and specify the namespaces properly
      //i18n.addResourceBundle('enCA', 'translation', enTranslation);
      //i18n.addResourceBundle('frCA', 'translation', frTranslation);

      //i18n.addResourceBundle('en', 'translation', enTranslation);
      //i18n.addResourceBundle('fr', 'translation', frTranslation);

      const tEnCa = i18n.getFixedT('enCA');
      const tFrCa = i18n.getFixedT('frCA');
      console.log ('Testing property "Header.about": default=' + t('Header.about') + ', en=' + tEnCa('Header.about') + ", fr=" + tFrCa('Header.about')); // -> same as i18next.t
  });



export default i18n;
