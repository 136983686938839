import React from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import proofIcon from "../assets/icons/Green/APX_Delivered._80x80.png";
import { Trans } from "react-i18next";

import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useNavigate, useLoaderData } from "react-router";

const PODModal = () => {
  const { orderImage, receivedBy } = useLoaderData();
  const navigate = useNavigate();

  const downloadImagesAsZip = () => {
    const zip = new JSZip();

    orderImage.forEach((imageData, index) => {
      // Convert base64 to a blob
      const byteCharacters = atob(imageData);
      const byteArrays = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i);
      }

      // Add the blob to the zip
      zip.file(`image_${index + 1}.png`, byteArrays, { binary: true });
    });

    // Generate the zip file
    zip.generateAsync({ type: "blob" }).then((blob) => {
      // Save the zip file using file-saver
      saveAs(blob, "images.zip");
    });
  };

  const onClose = () => {
    const path = "../..";
    navigate(path, { relative: "path" });
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img src={proofIcon} alt="sms" className="me-2" height="40px"></img>
          <Trans i18nKey="PODECommerceModal.title">
            View Proof of Delivery
          </Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center flex-column">
          {orderImage.length > 0 ? (
            <>
              <Carousel
                controls={orderImage.length > 1}
                indicators={orderImage.length > 1}
              >
                {orderImage.map((imageData, index) => (
                  <Carousel.Item key={index} style={{ minHeight: "300px" }}>
                    <img
                      style={{
                        objectFit: "scale-down",
                      }}
                      className="d-block w-100"
                      src={`data:image/png;base64,${imageData}`}
                      alt={`${index + 1}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <button
                className="btn btn-link mt-3"
                onClick={downloadImagesAsZip}
              >
                <Trans i18nKey="PODECommerceModal.downloadImages">
                  Download Images
                </Trans>
              </button>
            </>
          ) : (
            <p>
              <Trans i18nKey="PODECommerceModal.noImages">
                There is no POD image for this order.
              </Trans>
            </p>
          )}
          <br />
          <p className="text-center">
            <Trans i18nKey="PODECommerceModal.receivedBy">
              Received by: <b>{{ receivedBy }}</b>
            </Trans>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PODModal;
