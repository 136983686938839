import React, { useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import cancelIcon from "../assets/icons/New/Cancel.png";
import {
  VerifyCancelOrder,
  CancelOrder,
  HttpError,
  UnknownError,
  NotFoundError,
} from "../functions/ApiCalls";
import { useNavigate, useParams } from "react-router";
import { Trans, useTranslation } from "react-i18next";

const CancelOrderModal = () => {
  const { trackingNumber } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  //const [orderNumber, setOrderNumber] = useState();// #5782
  const [accountNumber, setAccountNumber] = useState();
  const [deliveryPostalCode, setDeliveryPostalCode] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [cancelName, setCancelName] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountNumberError, setIsAccountNumberError] = useState(false);
  const [isDestinationPostalCodeError, setIsDestinationPostalCodeError] =
    useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [isReasonError, setIsReasonError] = useState(false);

  const verifyPage1 = useCallback(
    async (event) => {
      event.preventDefault();

      setIsLoading(false);
      setIsAccountNumberError(false);
      setIsDestinationPostalCodeError(false);

      const formData = new FormData(event.target);
      const accountNumber = formData.get("accountNumber").trim();
      const deliveryPostalCode = formData.get("destinationPostalCode").trim();

      if (accountNumber === "") {
        setIsAccountNumberError(true);
      }

      if (deliveryPostalCode === "") {
        setIsDestinationPostalCodeError(true);
      }

      if (accountNumber === "" || deliveryPostalCode === "") {
        return;
      }

      setIsLoading(true);

      const results = await VerifyCancelOrder(
        trackingNumber, /*orderNumber, */    // #5782: accountNumber, 
        accountNumber,
        deliveryPostalCode
      );

      if (results instanceof HttpError) {
        setVerificationError(
          t("CancelOrderModal.loadingErrors.httpError")
        );
        setIsLoading(false);
        return;
      }

      if (results instanceof UnknownError) {
        setVerificationError(
          t("CancelOrderModal.loadingErrors.unknownError")
        );
        setIsLoading(false);
        return;
      }

      if (results instanceof NotFoundError) {
        setVerificationError(
          t("CancelOrderModal.loadingErrors.notFoundError")
        );
        setIsLoading(false);
        return;
      }

      setVerificationError("");
      setCurrentPage(currentPage + 1);
      // setOrderNumber(orderNumber);// #5782
      setAccountNumber(accountNumber);
      setDeliveryPostalCode(deliveryPostalCode);
      setIsLoading(false);
    },
    [currentPage, t]
  );

  const verifyPage2 = useCallback(
    async (event) => {
      event.preventDefault();

      setIsLoading(false);
      setIsNameError(false);
      setIsReasonError(false);

      const formData = new FormData(event.target);
      const name = formData.get("name").trim();
      const reason = formData.get("reason").trim();

      if (name === "") {
        setIsNameError(true);
      }

      if (reason === "") {
        setIsReasonError(true);
      }

      if (name === "" || reason === "") {
        return;
      }

      setCancelName(name);
      setCancelReason(reason);

      setCurrentPage(currentPage + 1);
    },
    [currentPage]
  );

  const cancelOrder = async () => {
    setIsLoading(true);

    const results = await CancelOrder(
      trackingNumber,
      accountNumber,
      deliveryPostalCode,
      cancelName,
      cancelReason
    );

    if (results instanceof HttpError) {
      setVerificationError(t("CancelOrderModal.cancelOrderErrors.httpError"));
      setIsLoading(false);
      return;
    }

    if (results instanceof UnknownError) {

      setVerificationError(t("CancelOrderModal.cancelOrderErrors.unknownError"));

      setIsLoading(false);
      return;
    }

    if (results instanceof NotFoundError) {
      setVerificationError(t("CancelOrderModal.cancelOrderErrors.notFoundError"));
      setIsLoading(false);
      return;
    }

    window.location.reload();
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleOnHide = () => {
    navigate("..", { relative: "path" });
  };

  const date = new Date();

  return (
    <>
      <Modal
        show={true}
        onHide={handleOnHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={cancelIcon}
              alt="delivery time"
              className="me-2"
              height="40px"
            ></img>
            <Trans i18nKey="CancelOrderModal.title">
              Cancel Your Order
            </Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentPage === 1 && (
            <form onSubmit={verifyPage1}>
              <fieldset
                disabled={isLoading}
                className="d-flex justify-content-center flex-column"
              >
                <p>
                  <Trans i18nKey="CancelOrderModal.prompt">
                    Please enter your account number{" "}
                    <b>
                      <u>and</u>
                    </b>{" "}
                    postal code so we can verify you.
                  </Trans>
                </p>

                <div className="mb-3">
                  <label htmlFor="accountNumber" className="form-label">
                    <Trans i18nKey="Generic.accountNumber">
                      Account Number
                    </Trans>
                  </label>
                  <input
                    type="text"
                    className={
                      isAccountNumberError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    id="accountNumber"
                    name="accountNumber"
                    aria-describedby="accountNumber"
                    placeholder="200211283"
                    required={true}
                    autoFocus={true}
                  />
                  <div className="invalid-feedback">
                    <Trans i18nKey="CancelOrderModal.accountNumberError">
                      Please enter a valid account number
                    </Trans>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="destinationPostalCode" className="form-label">
                    <Trans i18nKey="CancelOrderModal.postalCode">
                      Destination Postal Code
                    </Trans>
                  </label>
                  <input
                    type="text"
                    className={
                      isDestinationPostalCodeError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    id="destinationPostalCode"
                    name="destinationPostalCode"
                    aria-describedby="destinationPostalCode"
                    required={true}
                    autoFocus={true}
                    placeholder="L4W5J2"
                    maxLength="7"
                  />
                  <div className="invalid-feedback">
                    <Trans i18nKey="CancelOrderModal.postalCodeError">
                      Please enter a valid destination postal code
                    </Trans>
                  </div>
                </div>

                {verificationError && (
                  <b>
                    <p className="text-danger text-center">
                      {verificationError}
                    </p>
                  </b>
                )}
                <button className="btn btn-primary" type="submit">
                  {isLoading ? "..." : t("CancelOrderModal.continue")}
                </button>
              </fieldset>
            </form>
          )}

          {currentPage === 2 && (
            <form onSubmit={verifyPage2}>
              <fieldset
                disabled={isLoading}
                className="d-flex justify-content-center flex-column"
              >
                <p>
                  <Trans i18nKey="CancelOrderModal.details">
                    Enter some details related to your cancellation.
                  </Trans>
                </p>

                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    <Trans i18nKey="Generic.name">
                      Name
                    </Trans>
                  </label>
                  <input
                    type="text"
                    className={
                      isNameError ? "form-control is-invalid" : "form-control"
                    }
                    id="name"
                    name="name"
                    aria-describedby="name"
                    required={true}
                    autoFocus={true}
                  />
                  <div className="invalid-feedback">
                    <Trans i18nKey="CancelOrderModal.nameError">
                      Please enter a valid name
                    </Trans>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="reason" className="form-label">
                    <Trans i18nKey="CancelOrderModal.reason">
                      Reason
                    </Trans>
                  </label>
                  <textarea
                    className={
                      isReasonError ? "form-control is-invalid" : "form-control"
                    }
                    id="reason"
                    name="reason"
                    aria-describedby="reason"
                    required={true}
                    autoFocus={true}
                    style={{ height: "60px", resize: "none" }}
                    maxLength="50"
                  />
                  <div className="invalid-feedback">
                    <Trans i18nKey="CancelOrderModal.reasonError">
                      Please enter a valid reason
                    </Trans>
                  </div>
                </div>

                {verificationError && (
                  <b>
                    <p className="text-danger text-center">
                      {verificationError}
                    </p>
                  </b>
                )}
                <button className="btn btn-link" onClick={previousPage}>
                  <Trans i18nKey="Generic.goBack">
                    Go Back
                  </Trans>

                </button>
                <button className="btn btn-primary" type="submit">
                  {isLoading ? "..." : t("Generic.continue")}
                </button>
              </fieldset>
            </form>
          )}

          {currentPage === 3 && (
            <div className="d-flex justify-content-center flex-column">
              <p>
                <Trans i18nKey="CancelOrderModal.areYouSureTitle">
                  Are you sure you would like to cancel your order?
                </Trans>
              </p>
              <p>
                <Trans i18nKey="CancelOrderModal.areYouSureDetails">
                  Once you click submit, you will no longer be able to make
                  changes to your order and it will be{" "}
                  <b className="text-danger">Cancelled</b>.
                </Trans>
              </p>
              <div className="mt-4">
                <p>
                  <Trans i18nkey="CancelOrderModal.orderNumber">
                    Order Number: <b>{{ trackingNumber }}</b>
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="CancelOrderModal.dateCancelled">
                    Date of Cancellation: <b>{{ date }}</b>
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="CancelOrderModal.namePrompt">
                    Name: <b>{{ cancelName }}</b>
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="CancelOrderModal.reasonPrompt">
                    Reason: <b>{{ cancelReason }}</b>
                  </Trans>
                </p>
              </div>
              <button
                disabled={isLoading}
                className="btn btn-link"
                onClick={previousPage}
              >
                <Trans i18nKey="Generic.goBack">
                  Go Back
                </Trans>
              </button>
              <button
                disabled={isLoading}
                className="btn btn-primary"
                onClick={cancelOrder}
              >
                {isLoading ? "..." : t("CancelOrderModal.cancelMyOrder")}
              </button>
              {verificationError && (
                <b>
                  <p className="text-danger text-center">{verificationError}</p>
                </b>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CancelOrderModal;
