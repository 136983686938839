
import { useTranslation, Trans } from 'react-i18next';
import React, { useCallback } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';

export const LanguageSwitcherButton = () => {
    const { t, i18n: { language, changeLanguage } } = useTranslation();

    const handleLanguageSwitching = useCallback((language) => {
        changeLanguage(language, (err, t1) => {
            if (err) return console.log('Language could not be switched!', err);
            else console.log('Language switched to ' + language + ' successfully!');
            //t1('TrackOrderFormPage.title');
        })
    }, [changeLanguage]);

    return (
        <NavDropdown title={t(`Language.${language}`)} id="nav-dropdown">
            <NavDropdown.Item eventKey="4.1" disabled={language === "enCA"}
                onClick={() => {
                    handleLanguageSwitching("enCA")
                }}>
                <Trans i18nKey="Language.enCA">English Default</Trans>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="4.4" disabled={language === "frCA"}
                onClick={() => {
                    handleLanguageSwitching("frCA")
                }}>
                <Trans i18nKey="Language.frCA">French Default</Trans>
            </NavDropdown.Item>
        </NavDropdown>
    )
}